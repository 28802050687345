import React from "react"
import styled from "styled-components"
import { Link, graphql  } from "gatsby"

import Page from "../components/layout/Page"
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import PageHeader  from "../components/layout/PageHeader"
import HoverButton from "../components/buttons/HoverButton"

import { devices } from "../settings"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons"

const Content = styled.div`
    margin-left: 20px;
    padding-bottom: 50px;

    @media ${devices.mobile} {
        margin-left: 0px;
    }

    h3 {
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }
    > p, > div {
        margin-left: 20px;
    }
    > div img {
        margin: 20px;

        @media ${devices.mobile} {
            margin: 10px 0px;
        }        
    }
`

export default function NonStandardLetters({ data }) {
    const product = data.products.edges[0].product;
    const content = data.content.edges[0].content;
    
    return (
        <>
            <SEO page="productoption" title={content.title} />

            <Page 
                transparent
                padding={0}
                breadcrumb={[
                    {title: "Home", url:"/"},
                    {title: "Products", url:"/products/"},                    
                    {title: product.category, url: "/products/" + product.category_name + "/"},
                    {title: product.title, url: "/products/" + product.url + "/"},
                    {title: content.title, url: null}
                ]}
                >
                    <PageHeader 
                        title={content.title}
                    />
                    
                    <Content>
                        <h3>We can match your Font or Shape</h3>
                        <p>
                            If you have a type style or shape that you need that we do not offer as standard, we can produce your 
                            style with the same quality, speed and fair price that we offer for our standard products.
                        </p>
                        <div>
                            <GoogleImage root src={content.image_url} required lazy alt={content.title} />
                        </div>
                        <h3>How do we do it?</h3>
                        <p>
                            Our Lean manufacturing system makes it possible to build unique shape and type styles in a precise and 
                            efficient process. This is how we can build your custom shape in only 5 days. Our production team uses 
                            proven and consistent fabrication techniques so that no time is lost.
                        </p>
                        <h3>Do I need a "Custom" sign?</h3>
                        <p>
                            Actually every sign will build is "Custom", we rarely make the same exact sign twice, unless it is one 
                            of our repeat customers. Their is very little difference building your Shape then our own. The colors 
                            and face graphic are infinitely variable so that you can dial in the look you are after.
                        </p>
                        <h3>Why are there Standard Fonts and Shapes?</h3>
                        <p>
                            Since our Standard Styles and Shapes are pre-engineered, we can move to production slightly faster that 
                            a non standard styles. This will save you a little money using standard styles, simply because it saves 
                            us time.
                        </p>
                        <p>
                            We currently offer 10 standard type styles and about 60 standard shapes. If you have a type style or 
                            shape that you need that we do not offer as standard, we can produce your style with the same quality, 
                            speed and fair price that we offer for our standard products.
                        </p>
                        <p>
                            <Link to={"/products/" + product.url + "/standard/"}>
                                <HoverButton
                                    label="See Standard Shape and Type Styles"
                                    color="blue"
                                />
                            </Link>
                            {' '}
                            <Link to={"/sign-price-request/?from=NonStandardTypeStylePage"}>
                                <HoverButton
                                    label="Get Non-Standard Price"
                                    color="blue"
                                    icon={faDollarSign}
                                />
                            </Link>                            
                        </p>
                    </Content>

            </Page>
        </>
    )
}

export const query = graphql`
    query($productName: String!, $name: String!) {
        products: allMysqlProducts(filter: {name: {eq: $productName}}) {
            edges {
                product: node {
                    id
                    name
                    url
                    title
                    category
                    category_name
                }
            }
        }
        content: allMysqlProductContent(filter: {product_name: {eq: $productName}, name: {eq: $name}}) {
            edges {
                content: node {
                    id
                    type
                    name
                    title
                    description
                    optional_ind
                    image_url
                }
            }
        }
    }`